<template>
  <div class="formdata" style="padding: 20px">
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="问诊类型编码:" prop="consultationCode">
        <el-input
          v-model="form.consultationCode"
          v-check-permission
          placeholder="请输入问诊类型编码"
          :disabled="isEdit"
          style="width: 50%"
          maxlength="10"
        />
      </el-form-item>
      <el-form-item label="问诊类型名称:" prop="consultationName">
        <el-input
          v-model="form.consultationName"
          v-check-permission
          placeholder="请输入问诊类型名称"
          style="width: 50%"
          maxlength="20"
        />
      </el-form-item>
      <el-form-item label="问诊类型说明:" prop="direction">
        <el-input
          v-model="form.direction"
          placeholder="请输入问诊说明"
          style="width: 50%; margin-right: 20px"
          type="textarea"
          :rows="5"
          maxlength="200"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="价格:" prop="price">
        <el-input
          v-model="form.price"
          v-check-permission
          placeholder="请输入价格"
          style="width: 50%"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="saveData()"> 保存 </el-button>
        <el-button @click="goBack"> 返回 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { numRule, codeRule } from '@/utils/verificationRule.js';
export default {
  name: 'Details',
  data() {
    return {
      selectId: '', // 编辑或查看当前数据的id
      isEdit: Number(this.$route.query.type) === 1, // 1编辑，0新增
      form: {
        direction: '',
        consultationCode: '',
        consultationName: '',
        price: '',
      },
      rules: {
        consultationCode: [
          { required: true, validator: codeRule, trigger: 'change' },
        ],
        consultationName: [
          { required: true, message: '请输入问诊类型名称', trigger: 'change' },
        ],
        direction: [
          { required: true, message: '请输入问诊说明', trigger: 'change' },
        ],
        price: [{ required: true, validator: numRule, trigger: 'change' }],
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getConsultTypeDetail();
  },
  methods: {
    ...mapActions('basicDictionary', ['consultationTypeEdit']),
    // 问诊类型详情
    getConsultTypeDetail() {
      if (this.isEdit) {
        const detail = this.$route.query.detail;
        this.form = {
          direction: detail.direction,
          consultationCode: detail.consultationCode,
          consultationName: detail.consultationName,
          price: detail.price,
        };
        this.selectId = detail.id;
      }
    },
    // 编辑问诊类型
    saveData() {
      const param = {
        ...JSON.parse(JSON.stringify(this.form)),
      };
      if (this.isEdit) {
        param.id = this.selectId;
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.consultationTypeEdit(param).then((res) => {
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message: '保存成功',
              });
              this.$router.go(-1);
            }
          });
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
// 预约时间的可预约样式
</style>
<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.labelStyle {
  margin-right: 10px;
}

.serviceDetail {
  font-size: 16px;
  color: red;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.orderstatus {
  padding: 10px 5px;
  margin-left: 40px;
  border: 1px solid black;
}
</style>
